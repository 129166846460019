import React from 'react'
import CaseStudyLayout, {
  Quote,
  StarDivider,
  SliderDivider,
  CustomerPhoto,
  DevskillerHero,
  OtherCaseStudies,
} from '../../components/casestudy/caseStudyLayout'
import Seo from '../../components/seo'

import shareImage from '../../components/casestudy/images/devskillerShare.png'

const Devskiller = () => {
  const TITLE = 'DevSkiller: “With Probe we finally get metrics we can trust”'

  return (
    <CaseStudyLayout hero={<DevskillerHero />} title={TITLE}>
      <Seo
        title={TITLE}
        description={TITLE}
        keywords={[
          'chargebee',
          'devskiller.com',
          'saas',
          'excel',
          'spreadsheets',
          'bi',
          'analytics',
        ]}
        twitterImg={shareImage}
        mainpageImg={shareImage}
      />
      <CustomerPhoto
        name="Jakub Kubryński"
        title="CEO"
        company="DevSkiller"
        webPage="https://devskiller.com"
      />
      <div className="py-5">
        <h2>What do you do at DevSkiller?</h2>
        <p>My name is Jakub Kubryński and I am the CEO of DevSkiller.</p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What’s the problem that Probe is solving for you?</h2>
        <p>
          The number one problem for me was that I did not trust the numbers. I
          tried many tools and had to spend hours fixing the data. There were
          different problems: handling exchange rates, reflecting changes to
          original invoices, support for overdue payments and others. Probe
          addresses all these issues and I don't have to think about quality of
          the data, instead I focus on analyzing it.
        </p>
        <p>
          I’m also able to share my KPIs effectively. Investors kept asking for
          the metrics every month. This meant that every month I had to prepare
          documents to share. Now I simply share one link which is up-to-date
          all the time.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>
          Which other solutions have you tried before Probe? Why did they fail?
        </h2>
        <p>
          We use Chargebee so it was natural to try RevenueStory. We also used
          ChartMogul and ProfitWell. The problem with those tools for us was
          that the metrics presented were simply off.
        </p>
        <p>
          Big issue for me was how multiple currencies are handled. We invoice
          customers in USD and EUR and to present numbers we need to do
          conversion. Sadly, using these tools, we noticed huge discrepancies in
          our data due to this not being done correctly. I remember MRR from one
          of our top customers being off by 20% because of this!
        </p>
        <p>
          I also had constant problems with how churn and renewal is calculated
          because of overdue invoices. Whenever someone was late with a payment
          they got classified incorrectly. Later changes were not reflected in
          metrics resulting in a lot of manual work to fix this.
        </p>
      </div>
      <Quote text="With other tools I had to do a lot of manual work to fix incorrectly calculated metrics. After implementing Probe I can focus on getting the insights."></Quote>
      <div className="py-5">
        <h2>What was important for you when trying to solve this problem?</h2>
        <Quote text="It’s easy to come up with a nicely designed page that shows you some metrics. To have metrics that you can trust is a much, much harder task." />
        <p>
          There is a lot of nuance when calculating many of those numbers and I
          really feel that the Probe team got those details right. When talking
          with them I get the feeling that they truly understand each and every
          metric.
        </p>
        <p>
          I also like the idea of being able to manually fix single data points.
          Sometimes it’s too late to change the invoice in Chargebee. Probe
          gives us the opportunity to update the underlying numbers anyway -
          something that was not possible for us with other tools.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>What did the implementation process look like?</h2>
        <p>
          Native integration with Chargebee was very important. With previous
          tools we had to integrate via API which isn’t ideal. We also got full
          support from Probe.
        </p>
      </div>

      <Quote text="The support we get is not only for the tool we use. We also have custom consultations for our business-specific questions - not like in a typical SaaS." />

      <div className="py-5">
        <p>
          Probe works very closely with us, whenever we have some need or
          question they engage and react super fast. In my opinion it makes them
          very well adjusted to B2B market.
        </p>
        <p>
          Additionally since Probe is still relatively small, they keep us
          included in the product development process.
        </p>
      </div>
      <StarDivider />
      <div className="py-5">
        <h2>
          How did it change after adopting Probe? Did it become easier? Faster?
          Do you have any numbers to back this up?
        </h2>
        <p>
          Now I have full confidence in the results I see. With previous
          products fixing problems used to take up a lot of my time and I was
          never trusting the numbers I saw anyway. Now with Probe I can really
          focus on getting insights and use this data to help me make business
          decisions.
        </p>
        <p>
          I used to wait for accounting team to get the results but it took too
          long, often 1-2 weeks after the month has passed.
        </p>

        <p>
          Now I have all the numbers realtime, so I have full visibility all the
          time.
        </p>
      </div>
      <SliderDivider />
      <div className="pb-5"></div>
      <OtherCaseStudies names={['callpage', 'edrone', 'castle']} />
    </CaseStudyLayout>
  )
}

export default Devskiller
